import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import LinkCard from "../../../components/LinkCard"

const IndexPage = () => {
    return (
        <Layout pageTitle="Awards">
            <Row>
                <Col>
                    <Link to="/awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center ">
                {/* <LinkCard to="/awards/winners" title="Winner Badges &amp; Certificates" /> */}
                <LinkCard to="/awards/sme-awards/standard-graphics" title="Standard Graphics" />            
                <LinkCard to="/awards/sme-awards/templates" title="Templates" />
                <LinkCard to="/awards/sme-awards/press-releases" title="Press Releases" />
            </Row>
        </Layout>
    )
}

export default IndexPage
